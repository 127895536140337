import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './Tab.module.scss';

interface TabProps {
  active?: boolean;
  className?: string;
  color?: 'graphite' | 'white';
  icon?: React.ReactNode | string;
  isDisabled?: boolean;
  onClick: () => void;
  title: string;
  variant?: 'vertical' | 'horizontal';
}

function Icon({ icon }: { icon?: string | React.ReactNode }) {
  if (icon && typeof icon === 'string') {
    return <img src={icon} alt="" className={s.icon} />;
  }

  return icon;
}

export function Tab({
  active,
  className = '',
  color = 'graphite',
  icon,
  isDisabled,
  onClick,
  title,
  variant = 'horizontal',
}: TabProps) {
  const { clickAnalytics } = useAnalytics();

  const handleClick = () => {
    onClick();

    clickAnalytics({
      action: 'click',
      clickZone: 'body',
      clickElement: 'buttonclick',
      clickContent: `tab-${title}`,
      uniqueId: `tab-${title}`,
      transitionType: 'inside-link',
    });
  };

  return (
    <div
      className={cn(
        s.root,
        s[color],
        s[variant],
        { [s.active]: active, [s.disabled]: isDisabled },
        className,
      )}
      data-qa={active ? 'active-tab' : 'tab'}
      onClick={handleClick}
    >
      <span className={s.text} dangerouslySetInnerHTML={{ __html: title }} />
      <Icon icon={icon} />
    </div>
  );
}
