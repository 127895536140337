import cn from 'classnames';
import { Fragment } from 'react';
import { RichText } from 'src/components/RichText';
import { Wrapper } from 'src/components/Wrapper';
import { getIcon } from 'src/utils/getIcon';

import { Variant } from '../Button/constants';
import s from './Benefits.module.scss';
import { Buttons } from './Buttons';
import { PreviewCard } from './Cards/PreviewCard';
import { PrimaryCard } from './Cards/PrimaryCard';
import { AlignContent, Grid, Hover, TargetLinkType, Type } from './constants';
import { BenefitsProps } from './types';

export function Benefits({
  id,
  title,
  description,
  cards,
  cardWithShadow = false,
  cardWithNumeric = false,
  grid = Grid.TwoColumns,
  type = Type.PrimaryCard,
  alignContent = AlignContent.Top,
  buttons,
  onButtonsClick,
  isNumericBlack = false,
  className = '',
  classNameLink = '',
  withArrow,
  hover = Hover.Full,
}: BenefitsProps) {
  const componentCards = cards.map(
    ({ title, text, icon, slug, target, previewImage }, index) => {
      if (icon && typeof icon === 'string') icon = getIcon(icon);
      return (
        <Fragment key={`${title}_${index}`}>
          {type === Type.PrimaryCard ? (
            <PrimaryCard
              title={title}
              text={text}
              icon={icon}
              slug={slug}
              target={target}
              cardWithShadow={cardWithShadow}
              cardWithNumeric={cardWithNumeric}
              isNumericBlack={isNumericBlack}
              alignContent={alignContent}
              withArrow={withArrow}
              hover={hover}
            />
          ) : (
            <PreviewCard
              title={title}
              text={text || ''}
              slug={slug || ''}
              previewImage={previewImage}
            />
          )}
        </Fragment>
      );
    },
  );

  return (
    <section className={cn(s.root, className)} id={id}>
      <Wrapper className={s.wrapper}>
        {(title || description) && (
          <header className={s.header}>
            {title && (
              <h2
                className={s.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {description && <RichText richText={description} />}
          </header>
        )}
        <div
          className={cn(s.listItems, s[grid], {
            [s.smallGap]: cardWithShadow,
            [s.numericList]: cardWithNumeric,
            [s.mediumTwoColumns]: type === Type.PreviewCard,
          })}
        >
          {componentCards}
        </div>

        {buttons && buttons.length >= 1 && (
          <div className={s.buttonsWrapper}>
            <Buttons
              buttons={buttons}
              onButtonsClick={onButtonsClick}
              classNameLink={classNameLink}
            />
          </div>
        )}
      </Wrapper>
    </section>
  );
}

Benefits.grid = Grid;
Benefits.buttonVariants = Variant;
Benefits.alignContent = AlignContent;
Benefits.type = Type;
Benefits.targetLinkType = TargetLinkType;
Benefits.hover = Hover;
