import * as icons from 'src/uikit/icons/ContentIcons/TechIcons';

type IconType = {
  [key: string]: React.ComponentType<{ className?: string }>;
};

export const getIcon = (name: string): string | JSX.Element => {
  if ((icons as IconType).hasOwnProperty(name)) {
    const IconComponent = (icons as IconType)[name];
    return <IconComponent />;
  } else {
    return name;
  }
};
