import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { Tab } from './Tab';
import s from './Tabs.module.scss';

export type TabType = {
  id: string;
  title: string;
  slug?: string;
  isDisabled?: boolean;
  icon?: React.ReactNode | string;
};

export interface TabsProps {
  activeTab?: string;
  className?: string;
  color?: 'graphite' | 'white';
  classNameContainer?: string;
  classNameTab?: string;
  onClick: (activeTab: TabType) => void;
  tabs: TabType[];
  variant?: 'vertical' | 'horizontal';
}

export function Tabs({
  activeTab,
  className = '',
  color = 'graphite',
  classNameContainer = '',
  classNameTab = '',
  onClick,
  tabs,
  variant = 'horizontal',
}: TabsProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  const [variantMedia, setVariantMedia] = useState(variant);

  useEffect(() => {
    if (variant === 'vertical' && windowWidth && windowWidth < MD) {
      setVariantMedia('horizontal');
    }
    setVariantMedia(variant);
  }, [windowWidth, variant]);

  return (
    <div className={cn(s.root, s[variantMedia], s[color], className)}>
      <div className={cn(s.container, classNameContainer)}>
        {tabs.map((tab, index) => (
          <Tab
            active={tab.id === activeTab}
            className={classNameTab}
            color={color}
            isDisabled={tab.isDisabled}
            key={index}
            onClick={() => onClick(tab)}
            title={tab.title}
            variant={variantMedia}
            icon={tab.icon}
          />
        ))}
      </div>
    </div>
  );
}
