import cn from 'classnames';

import s from './RichText.module.scss';

type RichTextProps = {
  className?: string;
  richText: string | undefined;
};

export function RichText({ className, richText = '' }: RichTextProps) {
  return (
    <div
      className={cn(s.richText, className)}
      dangerouslySetInnerHTML={{ __html: richText }}
    />
  );
}
